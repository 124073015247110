<h1>Qu'est ce qu'est la socio esthétique</h1>

<p>
La socio esthétique est la pratique professionnelle de soins esthétiques dont le but est un accompagnement corporel de la souffrance 
pour un mieux-être, la reconstitution de l'image de soi, réhabiliter la perception de la dignité, favoriser la socialisation des personnes, 
rompre l'isolement. 
</p>

<p>
La socio esthéticienne apporte un geste et une écoute non médicalisés contribuant à une prise en charge globale de la personne 
grâce à des outils d'accompagnement comme le soin du visage, le conseil en image, le modelage corporel, le soin des mains...
afin de permettre de répondre à différents objectifs inscrits dans les projets de vie. 
</p>

<p>
La socio esthéticienne apporte également un échange, un moment de détente et de bienveillance.
</p>

<p>
La socio esthétique se pratique dans les structures médicales, sociales ou médico-sociales. 
Elle est au coeur de l'accompagnement de la personne fragilisée atteinte dans son intégrité physique, psychique ou en détresse sociale.
Elle s'adresse à tous : hommes et femmes, enfants, adolescents, adultes et personnes âgées.
</p>

<p>
Grâce à ma formation au sein du CHU de Tours à l'école du CODES et grâce à mon expérience, 
je suis en capacité d'adapter les soins que je pratique aux pathologies, aux situations et aux besoins que j'identifie 
et indiqués par les équipes professionnelles ou exprimés par le bénéficiaire.
<br/>
Je suis aussi formée au socio conseil en image et à l'aromathérapie ce qui me permet de proposer et d'adapter mes soins 
en fonction des besoins des bénéficiaires.
</p>
