<h1>Partenaires</h1>

<div class="row">
  <div class="col-md-4">
	<h2 class="center-text pb-5">ARSE Pays de Loire Bretagne</h2>
	<img src="assets/partenaires-arse.png" class="img-fluid"/>
  </div>

  <div class="col-md-4">
	<h2 class="center-text pb-5">Adhésion Fédération Nationale de Socio-esthétique</h2>
	<img src="assets/partenaires-fnse.png" class="img-fluid"/>
  </div>

  <div class="col-md-4">
	<h2 class="center-text pb-5">UCAH - Union des Commerçants et Artisans de l'Herbergement</h2>
	<img src="assets/partenaires-ucah.png" class="img-fluid"/>
  </div>
</div>