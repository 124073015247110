import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-presta-collectif',
  templateUrl: './presta-collectif.component.html',
  styleUrls: ['./presta-collectif.component.css']
})
export class PrestaCollectifComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
