
	<h1>Mon parcours</h1>
	<p>
	Je m'appelle Vanessa.
	<br/>
	Douce, souriante, passionnée, je suis à l'écoute
	et j'aime prendre soin des autres mais aussi me rendre utile auprès des personnes.
	<br/>
	Je suis titulaire d'un CAP Esthétique-Cosmétique ( école MORISS à Nantes ), 
	un BP esthétique-cosmétique (CFA de la coiffure et de l’esthétique à Nantes)
	ainsi qu'un certificat professionnel de Socio-esthéticienne CODES- Cours d’Esthétique à Option Humanitaire et Sociale- à Tours (37) 
	titre de Socio-esthéticienne inscrit au RNCP niveau IV en Janvier 2019. 
	<br/>
	J’ai exercé le métier d’esthéticienne en institut pendant 14 ans, 
	et j’ai obtenu mon titre de socio-esthéticienne au CODES (Cours d’Esthétique à Option Humanitaire et Sociale) 
	que j’ai effectué à Tours en Janvier 2019.
	<br/>
	Mon expérience acquise depuis plus de dix ans en tant qu’esthéticienne me permet d’être à l’écoute, 
	d’apporter de la détente grâce aux soins et aux massages, d’échanger et de conseiller les patients. 
	Les soins proposés en socio-esthétique développent un bien-être, restaurent l’image et l’estime de soi, 
	apportent un accompagnement non médicalisé. Ces différents points me paraissent indispensable pour les personnes souffrantes et fragilisées 
	afin qu’elles se sentent mieux.
	<br/>
	Je travaille actuellement dans une MAS, pour différentes structures de l’AREAMS, dans une MECS apprentis d’Auteuil, en Foyer de vie, 
	dans un FAM où je suis au contact de différents publics (personnes handicapées moteurs ou souffrant de déficience intellectuelle, 
	personnes âgées, étrangères, en réinsertion, ou malades). 
	Je m’adapte et propose des soins individuels ou des ateliers collectifs en fonction des besoins et des problématiques de chacun. 
	Je travaille également en concertation avec les équipes pluridisciplinaires.

	</p>




