import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ParcoursComponent } from './parcours/parcours.component'; 
import { StatutComponent } from './statut/statut.component'; 
import { SocioEstethiqueComponent } from './socio-estethique/socio-estethique.component';
import { InterventionsComponent } from './interventions/interventions.component'; 
import { PrestaIndividuelComponent } from './presta-individuel/presta-individuel.component';
import { PrestaCollectifComponent } from './presta-collectif/presta-collectif.component';
import { ObjectifsComponent } from './objectifs/objectifs.component';
import { TarifsComponent } from './tarifs/tarifs.component';
import { CarteCadeauxComponent } from './carte-cadeaux/carte-cadeaux.component';
import { PresseComponent } from './presse/presse.component';
import { PhotosComponent } from './photos/photos.component';
import { VideosComponent } from './videos/videos.component';
import { PartenairesComponent } from './partenaires/partenaires.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  { path: '', redirectTo: '/parcours', pathMatch: 'full' },
  { path: 'parcours', component: ParcoursComponent },
  { path: 'statut', component: StatutComponent },
  { path: 'socio-esthetique', component: SocioEstethiqueComponent },
  { path: 'interventions', component: InterventionsComponent },
  { path: 'presta-individuel', component: PrestaIndividuelComponent },
  { path: 'presta-collectif', component: PrestaCollectifComponent },
  { path: 'objectifs', component: ObjectifsComponent },
  { path: 'tarifs', component: TarifsComponent },
  { path: 'carte-cadeaux', component: CarteCadeauxComponent },
  { path: 'presse', component: PresseComponent },
  { path: 'photos', component: PhotosComponent },
  { path: 'videos', component: VideosComponent },
  { path: 'partenaires', component: PartenairesComponent },
  { path: 'contact', component: ContactComponent },  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
