<h1>Prestations - Soins collectifs</h1>
<ul>
<li>L'hygiène</li>
<li>La fabrication de produits</li>
<li>Le soin du visage</li>
</ul>

<p>
Par l'écoute active, les conseils personnalisés et la prévention, les soins proposés en socio-esthétique développent un bien-être, 
et restaurent l'image et l'estime de soi. 
Ces différents points paraissent indispensables pour les personnes souffrantes et fragilisées afin qu’elles se sentent mieux.
</p>

<p>
Les ateliers collectifs peuvent être réalisés avec un groupe de 6 à 8 personnes sur un temps pouvant aller de 2h à 3h
(temps comprenant l'installation et le rangement du matériel.)
</p>

<p>
Un bilan sera restitué aux équipes pluridisciplinaires pour les soins individules et ateliers collectifs.
</p>