<h1>Carte Cadeau</h1>

<p>
"Le plaisir d'offrir et la joie de recevoir"
</p>
<p>
Vous souhaitez faire plaisir ou faire découvrir un soin à un de vos proches !
</p>
<p>
Vous recherchez une idée cadeau pour un anniversaire, noël ou pour toutes occasions !
</p>
<p>
Je vous propose des cartes cadeaux.
</p>
<p>
Vous choisissez le montant ou le soin de votre choix.
</p>
<p>
La carte cadeau a une validité de 6 mois.
</p>
<p>
Contactez-moi !
</p>

<img src="assets/carte-cadeau.png"/>