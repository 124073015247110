<h1>Prestations - Soins individuels</h1>
<ul>
<li>Soins des mains</li>
<li>Soins du visage</li>
<li>Modelages</li>
<li>Epilations</li>
<li>Beauté des pieds</li>
<li>Maquillage</li>
<li>Conseils en image</li>
<li>Conseils produits</li>
</ul>

