import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-socio-estethique',
  templateUrl: './socio-estethique.component.html',
  styleUrls: ['./socio-estethique.component.css']
})
export class SocioEstethiqueComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
