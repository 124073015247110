<h1>Lieux d’intervention</h1>

<h2>En milieu médical </h2>
Hôpitaux et cliniques (tous services : cancérologie, soins palliatifs, psychiatrie, gériatrie, oncopédiatrie, ...)

<h2>En milieu médico-social</h2>
EHPAD, centres de rééducation fonctionnelle, ESAT, foyers de vie pour personnes handicapées, MAS, SAMSAH/SAVS, espaces santé jeunes, ...

<h2>En milieu social</h2>
MECS, centre maternel, maison d’arrêt, centre d’insertion professionnelle, centre d’hébergement et de réinsertion Sociale, réfugiés, ...

