<h1>Objectifs</h1>
<ul>
<li>Amélioration et valorisation de l’image de soi</li>
<li>Restaurer la confiance et l’estime de soi</li>
<li>Apporter un moment de détente et de bien-être</li>
<li>Travailler sur l’autonomie de soi et les règles d’hygiène corporel</li>
<li>Etre acteur de son bien-être</li>
<li>Favoriser l’inclusion sociale</li>
<li>Prendre conscience de son corps</li>
</ul>
