<header>
  <div class="container-fluid">
  
    <div class="row">
      <div class="col bandeau">        
      </div>
    </div>
	
	<div class="row mt-n4 align-items-center">
      <div class="col-md-5 px-5">        
		<img src="assets/logo.png" class="img-fluid"/>
      </div>
	  <div class="col-md-2">        
		<img src="assets/pictos.png" class="img-fluid"/>
      </div>
	  <div class="col-md-2 offset-md-2">    
		<div class="row mt-n4 ml-5">
		  <div class="col-md-2 offset-md-6 px-1">
		    <a href="https://www.facebook.com/vanessan.socioestheticienne" target="new">
			  <img src="assets/icone-facebook.png" class="img-fluid">
			</a>
		  </div>
		  <div class="col-md-2 ml-n1 px-1">
		    <a href="https://www.linkedin.com/in/vanessa-nocet-955046174/" target="new">
			  <img src="assets/icone-linkedin.png" class="img-fluid">
			</a>
		  </div>
		</div>
		<div class="row">
		  <div class="col-md-12 mt-2">
		    <img src="assets/contact_numero.jpg" class="img-fluid"/>
		  </div>
		</div>		
      </div>
    </div>
	
	<div class="row">
      <div class="col-md-9 offset-md-1"> 
		  <!-- Fixed navbar -->
		  <nav class="navbar navbar-expand-md navbar-light">
				
			<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav mr-auto">
				  <!--li class="nav-item active">
					<a class="nav-link" href="#">Accueil <span class="sr-only">(current)</span></a>
				  </li>
				  
				  <li><span class="navbar-pipe" href="#">|</span></li-->
				  
				  <li class="nav-item dropdown">
					<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					  Accueil
					</a>
					<div class="dropdown-menu" aria-labelledby="navbarDropdown">
					  <a class="dropdown-item" routerLink="/parcours">Mon parcours</a>
					  <a class="dropdown-item" routerLink="/statut">Statut</a>					  
					</div>
				  </li>
				  
				  <li><span class="nav-link" href="#">|</span></li>
				  
				  <li class="nav-item dropdown">
					<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						La socio-esthétique
					</a>
					<div class="dropdown-menu" aria-labelledby="navbarDropdown">
					  <a class="dropdown-item" routerLink="/socio-esthetique">Qu'est-ce que c'est</a>
					  <a class="dropdown-item" routerLink="/interventions">Lieux d'intervention</a>					  
					</div>					
				  </li>
				  
				  <li><span class="nav-link" href="#">|</span></li>
				  
				  <li class="nav-item dropdown">
					<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						Prestations
					</a>
					<div class="dropdown-menu" aria-labelledby="navbarDropdown">
					  <a class="dropdown-item" routerLink="/presta-individuel">Individuel</a>
					  <a class="dropdown-item" routerLink="/presta-collectif">Collectif</a>	
					  <a class="dropdown-item" routerLink="/objectifs">Objectifs</a>
					  <!--a class="dropdown-item" routerLink="/tarifs">Tarifs</a-->						  
					</div>						
				  </li>
				  
				  <li><span class="nav-link" href="#">|</span></li>
				  
				  <li class="nav-item">
					<a class="nav-link" routerLink="/carte-cadeaux">Cartes cadeaux</a>
				  </li>
				  
				  <li><span class="nav-link" href="#">|</span></li>
				  
				  <li class="nav-item dropdown">
				    <a class="nav-link" routerLink="/presse">Médias</a>
					<!--a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						Médias
					</a>
					<div class="dropdown-menu" aria-labelledby="navbarDropdown">
					  <a class="dropdown-item" routerLink="/presse">Presse</a>
					  <a class="dropdown-item" routerLink="/photos">Photos</a>	
					  <a class="dropdown-item" routerLink="/videos">Vidéos</a>					  
					</div-->					
				  </li>
				  
				  <li><span class="nav-link" href="#">|</span></li>
				  
				  <li class="nav-item">
					<a class="nav-link" routerLink="/partenaires">Partenaires</a>
				  </li>
				  
				  <li><span class="nav-link" href="#">|</span></li>
				  
				  <li class="nav-item">
					<a class="nav-link" routerLink="/contact">Me contacter</a>
				  </li>
		
				</ul>
			</div>

		  </nav>
      </div>
    </div>
	
  </div>
</header>

<!-- Begin page content -->

<main>
  <div class="container-fluid">	
	<div class="row">
	  <div class="col-md-12">
		<div class="tache">
		  <img src="assets/tache.jpg">
		</div>
		<div class="container-fluid contenu">
		  <div class="row">
			<div class="col-md-8 offset-md-1">
			  <router-outlet></router-outlet>
			</div>
		  </div>
		  <div class="row align-items-end mt-3">
			<div class="col-md-1 offset-md-4">
			  <img src="assets/logobas.jpg" class="img-fluid"/>	
			</div>
			<div class="col-md-1">
			  <img src="assets/contact.jpg" class="img-fluid"/>					
			</div>
			<!--div class="col-md-2">
			  Mentions légales			
			</div-->
		  </div>		
		</div>		
	  </div>
	</div>		
  </div>
</main>
