import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ParcoursComponent } from './parcours/parcours.component';
import { StatutComponent } from './statut/statut.component';
import { SocioEstethiqueComponent } from './socio-estethique/socio-estethique.component';
import { InterventionsComponent } from './interventions/interventions.component';
import { PrestaIndividuelComponent } from './presta-individuel/presta-individuel.component';
import { PrestaCollectifComponent } from './presta-collectif/presta-collectif.component';
import { ObjectifsComponent } from './objectifs/objectifs.component';
import { TarifsComponent } from './tarifs/tarifs.component';
import { CarteCadeauxComponent } from './carte-cadeaux/carte-cadeaux.component';
import { PresseComponent } from './presse/presse.component';
import { PhotosComponent } from './photos/photos.component';
import { VideosComponent } from './videos/videos.component';
import { PartenairesComponent } from './partenaires/partenaires.component';
import { ContactComponent } from './contact/contact.component';

@NgModule({
  declarations: [
    AppComponent,
    ParcoursComponent,
    StatutComponent,
    SocioEstethiqueComponent,
    InterventionsComponent,
    PrestaIndividuelComponent,
    PrestaCollectifComponent,
    ObjectifsComponent,
    TarifsComponent,
    CarteCadeauxComponent,
    PresseComponent,
    PhotosComponent,
    VideosComponent,
    PartenairesComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule	
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
