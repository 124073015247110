import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-presta-individuel',
  templateUrl: './presta-individuel.component.html',
  styleUrls: ['./presta-individuel.component.css']
})
export class PrestaIndividuelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
