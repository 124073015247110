<h1>Statuts</h1>


<h2>L'entreprise individuelle</h2>

<p>
Je suis inscrite au Répertoire des Entreprises et des Établissements (SIRENE) depuis le 1er Avril 2019.
<br/>
Je propose des soins individuels en structures ou à domicile ainsi que des ateliers collectifs 
sur des lieux d'accueils (MECS, MAS, SAMSAH/SAVS, Foyer de vie...).
<br/>
Je mets à votre disposition mon écoute, ma bienveillance et ma douceur, associées à ma technicité, pour vous accompagner.
</p>

<h2>Autre statut possible</h2>
<p>
Je peux également intervenir comme salarié d'une structure (ce qui est le cas à la MECS les apprentis d’auteuil de Challans 
Si vous êtes un centre hospitalier, une structure médico-sociale, une association, etc... Vous avez la possibilité de me salarier à temps partiel (respectant les conditions légales du Code du Travail).
<br/>
Dans tous les cas, des contrats sont établis afin de définir la prestation demandée, le temps et les conditions de travail, le taux horaires et les frais de route...
<br/>
Pour toutes demandes d'informations supplémentaires, n'hésitez pas à me contacter : <a href="mailto:vanessan.socio.estheticienne@gmail.com">ici</a>
</p>
<!--h2>Secteur d’intervention</h2-->

